import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/Seo'

import Cover from '../components/Cover'
import VideoBox from '../components/VideoBox'
import CTA from '../components/Cta'

import coverImage from '../images/rapporto-rischi-rendimenti.png'

import posterRischiRendimenti from '../images/poster-rapporto-rischi-rendimenti.png'

const Impulsivita = () => (
  <Layout>
    <SEO title="Il rapporto fra rischi e rendimenti" />
    <Cover
      title="Il rapporto fra rischi e rendimenti"
      color="white"
      bg={coverImage}
      icon=""
      link="#video"
    >
      <p>
        Possiamo individuare tre livelli, in linea di massima, che compongono la
        cosiddetta piramide degli investimenti, sovrapposti per crescente
        propensione al rischio e possibilità di rendimento. Vediamo insieme una
        sintetica rappresentazione di questo percorso ascensionale.
      </p>
    </Cover>
    <VideoBox ytID="L2aVqAydMNY" poster={posterRischiRendimenti} />
    <CTA />
  </Layout>
)

export default Impulsivita
